<template>
  <v-tabs
    grow
    show-arrows
    background-color="red lighten-5"
  >
    <template v-for="t in e.teams">
      <v-tab
        :key="t.team._id"
        v-if="isHeim || isTeam(t.team._id)"
      >
        {{ t.team.name }}
      </v-tab>
    </template>

    <template v-for="t in e.teams">
      <v-tab-item
        :key="t.team._id"
        v-if="isHeim || isTeam(t.team._id)"
      >
        <v-tabs
          grow
          show-arrows
          v-model="tab"
          background-color="grey lighten-4"
        >
          <v-tab href="#trainer">Trainer</v-tab>
          <v-tab href="#start">Startaufstellung</v-tab>
          <v-tab v-if="e.mode !== 'wk_score4'" href="#disciplines">Geräteaufstellung</v-tab>

          <v-tab-item value="trainer">
            <trainer :e="e" :df="df" :id="id" :team="t.team._id" />
          </v-tab-item>
          <v-tab-item value="start">
            <startaufstellung :e="e" :df="df" :id="id" :team="t.team._id" />
          </v-tab-item>
          <v-tab-item v-if="e.mode !== 'wk_score4'" value="disciplines">
            <geraete :e="e" :df="df" :id="id" :team="t.team._id" />
          </v-tab-item>
        </v-tabs>
      </v-tab-item>
    </template>
  </v-tabs>
</template>

<script>
import { useAuth } from '@/plugins/auth'

export default {
  name: 'mannschaften',

  components: {
    Trainer: () => import('./trainer'),
    Startaufstellung: () => import('./startaufstellung.vue'),
    Geraete: () => import('./geraete.vue')
  },

  setup (props, context) {
    return {
      ...useAuth(context)
    }
  },

  props: {
    id: {
      type: String,
      requires: true
    },
    e: {
      type: Object,
      required: true
    },
    df: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    tab: 'trainer'
  }),

  computed: {
    isHeim () {
      return this.isMaster.value || this.isAdmin(this.id) || this.e.teams?.filter(t => t.home).some(t => this.isTeam(t.team._id))
    }
  }
}
</script>

<style scoped>

</style>
